import React from "react";

function CloseIcon(props) {
    return (
        <div className="close-icon" onClick={props.onClick}>
            <svg viewBox="0 0 25.42 25.42">
                <path d="M2.24,0.29l22.89,22.89c0.39,0.39,0.39,1.02,0,1.41l-0.53,0.53
	c-0.39,0.39-1.02,0.39-1.41,0L0.29,2.24c-0.39-0.39-0.39-1.02,0-1.41l0.53-0.53C1.21-0.1,1.85-0.1,2.24,0.29z"/>
                <path d="M25.13,2.24L2.24,25.13c-0.39,0.39-1.02,0.39-1.41,0L0.29,24.6
	c-0.39-0.39-0.39-1.02,0-1.41L23.19,0.29c0.39-0.39,1.02-0.39,1.41,0l0.53,0.53C25.52,1.21,25.52,1.85,25.13,2.24z"/>
            </svg>
        </div>
    );
}

export default CloseIcon;

