import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'de',
        //debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath: '/locales//{{lng}}/{{ns}}.json?v=0.15',
            requestOptions: {
                cache: 'no-store',
            },
        }
    })
    .then(() => {
        i18n.services.formatter.add('lowercase', (value, lng, options) => {
            return value.toLowerCase();
    });
});


export default i18n;