import {css} from 'styled-components';
import * as vars from "css/vars";

const Common = css`
  html {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: ${vars.FONT_REGULAR};
    font-weight: 400;
    font-size: 0;
    overflow: hidden;
    background-color: ${props => props.theme.bodyColor};
    color: ${props => props.theme.textGreyDark};
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  #root {
    height: 100%;
  }

  .ReactModal__Body--open {
    overflow: hidden;
  }
`;

export default Common;