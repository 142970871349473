import {css} from 'styled-components';
import * as vars from "css/vars";

const Form = css`
  .medium-form {
    position: relative;
    width: 100%;
    margin: 0 auto;

    .form-textinput {
      position: relative;

      .form-textinput-label {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        font-size: ${vars.FONT_SIZE_NORMAL};
        line-height: 28px;
      }

      .measureHelper {
        position: absolute;
        visibility: hidden;
        white-space: nowrap;
        font-size: ${vars.FONT_SIZE_NORMAL};
      }
    }

    .form-error {
      position: relative;
      right: 0;
      display: flex;
      background-color: ${vars.WARNING_COLOR};
      padding: 5px 10px;
      align-items: center;
      line-height: 28px;
      z-index: 1;
      margin-left: 20px;
      max-width: 60%;

      .form-error-label {
        color: #fff;
        font-size: 14px;
        line-height: 18px;
      }

      &:before {
        content: "";
        position: absolute;
        left: -4px;
        top: 6px;
        width: 16px;
        height: 16px;
        background-color: ${vars.WARNING_COLOR};
        transform: rotate(45deg);
        z-index: -1;
      }
    }

    .select {
      display: flex;
      align-items: center;
    }

    .search {
      flex-grow: 1;
      position: relative;

      .form-error {
        position: absolute;
        right: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 28px;
        display: flex;
        background-color: ${vars.WARNING_COLOR};
        padding: 5px 10px;
        align-items: center;
        line-height: 28px;
        z-index: 1;
        margin-left: 20px;
        max-width: 60%;

        .form-error-label {
          color: #fff;
          font-size: 14px;
          line-height: 18px;
        }

        &:before {
          content: "";
          position: absolute;
          left: -4px;
          top: 6px;
          width: 16px;
          height: 16px;
          background-color: ${vars.WARNING_COLOR};
          transform: rotate(45deg);
          z-index: -1;
        }
      }
    }
  }

  input {
    font: inherit;
    color: inherit;

    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${props => props.theme.textGreyDark};
    }

    :-ms-input-placeholder {
      color: ${props => props.theme.textGreyDark};
    }
  }
`;

export default Form;