import React, {Suspense, useState, useEffect} from 'react';
import './css/app.css';
import UserOrGuest from "UserOrGuest";
import GlobalStyle from "css/GlobalStyle";
import {ThemeProvider} from "styled-components";
import * as vars from 'css/vars';
import i18next from "i18next";

function App() {
    const [webfont, setWebfont] = useState(false);
    const [theme, setTheme] = useState(localStorage.getItem('light-mode') === 'true' ? vars.LIGHT : vars.DARK);

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('lang') ? localStorage.getItem('lang') : 'de');

        import('webfontloader').then(WebFont =>
            WebFont.load({
                custom: {
                    families: ['AvenirNextLTPro-Regular', 'AvenirNextLTPro-Demi']
                },
                active: function () {
                    setWebfont(true);
                }
            })
        )
    }, []);

    const onChangeTheme = (t) => {
        setTheme(vars[t]);
    };

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle/>
            <Suspense fallback={<div>Loading...</div>}>
                {webfont &&
                    <UserOrGuest onChangeTheme={onChangeTheme}/>
                }
            </Suspense>
        </ThemeProvider>
    );
}

export default App;
