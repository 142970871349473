import {css} from 'styled-components';
import * as vars from "css/vars";

const UI = css`
  .oe-link {
    display: table;
    font-size: 14px;
    line-height: 20px;
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.25s;

    &:hover, &:focus {
      color: ${vars.GREEN};
    }
  }

  .greeting {
    text-align: center;
    font-size: 24px;
    margin: 10px auto 0;
    max-width: 720px;

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  .text-bold {
    font-family: ${vars.FONT_DEMI};
  }

  .text-green {
    color: ${vars.GREEN};
  }

  .text-red {
    color: ${vars.WARNING_COLOR};
  }

  .oe-row {
    border-bottom: 1px solid ${props => props.theme.borderGreyDark};
    display: flex;
    padding: 11px 0 10px;
    width: 100%;

    &:last-child {
      border-bottom: none;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .oe-row-label {
      font-size: ${vars.FONT_SIZE_NORMAL};
      width: 185px;
      min-width: 185px;
      line-height: 28px;
      padding-right: 10px;

      @media (max-width: 767px) {
        padding: 0 4px;
        min-width: 0;
        width: 100%;
        font-size: 14px;
      }

      span {
        position: relative;
        padding-right: 24px;

        .info-icon {
          position: absolute;
          right: 0;
        }

        &.no-break {
          white-space: nowrap;
        }
      }
    }

    .oe-row-value {
      font-size: ${vars.FONT_SIZE_NORMAL};
      line-height: 28px;
      padding-left: 4px;

      .oe-button {
        margin-left: 20px;

        &.no-margin-left {
          margin-left: 0;
        }
      }

      .warning {
        color: ${vars.WARNING_COLOR};
      }
    }

    .oe-row-second-level {
      display: flex;
      flex-grow: 1;
      flex-direction: column;

      @media (max-width: 767px) {
        width: 100%;
      }

      .oe-row {
        &:first-child {
          padding-top: 0;
        }

        .oe-row-label {
          padding-left: 4px;
        }
      }
    }

    .radio-item, .switch {
      padding-left: 4px;
    }

    &.red {
      border-color: ${vars.WARNING_COLOR};

      .oe-row-label {
        color: ${vars.WARNING_COLOR};
      }
    }
  }

  .info-icon {
    width: 15px;
    height: 15px;
    border: 1px solid ${props => props.theme.textGreyDark};
    border-radius: 50%;
    font-size: 11px;
    font-family: ${vars.FONT_DEMI};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
    cursor: pointer;
  }

  .oe-button {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 6px;
    font-family: ${vars.FONT_DEMI};
    cursor: pointer;
    text-align: center;
    outline: none;
    background-color: transparent;
    white-space: nowrap;
    transition: background-color 0.25s;
    transition-property: background-color, color, fill;
    color: ${props => props.theme.textGreyLight};
    fill: ${props => props.theme.textGreyLight};

    &:hover {
      color: ${props => props.theme.bodyColor};
      fill: ${props => props.theme.bodyColor};
    }

    &.bright, &.dark {
      border-color: ${props => props.theme.textGreyDark};

      &:hover {
        background-color: ${props => props.theme.textGreyDark};
        color: #000;
      }
    }

    .items {
      position: absolute;
      right: 10px;
      top: -10px;
      background-color: ${vars.WARNING_COLOR};
      border-radius: 11px;
      height: 22px;
      padding: 1px 6px 0;
      color: #fff;
      font-family: ${vars.FONT_DEMI};
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.extrasmall {
      padding: 3px 10px 4px;

      .icon-pre {
        margin: 0 10px 2px 0;
      }

      .label {
        font-size: 16px;
        line-height: 16px;
        padding-top: 3px;
      }

      .icon {
        margin: 0 0 2px 10px;
      }
    }

    &.small {
      padding: 9px 15px 10px;

      .icon-pre {
        margin: 0 10px 0 0;
      }

      .label {
        font-size: ${vars.FONT_SIZE_NORMAL};
        line-height: ${vars.FONT_SIZE_NORMAL};
        padding-top: 3px;
      }

      .icon {
        margin: 0 0 0 10px;
      }
    }

    &.medium {
      padding: 12px 15px 13px;

      .icon-pre {
        margin: 0 10px 0 0;
      }

      .label {
        font-size: 22px;
        line-height: 22px;
        padding-top: 3px;
      }

      .icon {
        margin: 0 0 0 10px;
      }
    }

    &.big {
      padding: 14px 22px 15px;
      width: 340px;

      @media (max-width: 767px) {
        max-width: 100%;
      }

      .icon-pre {
        margin: 0 16px 0 0;
      }

      .label {
        font-size: 28px;
        line-height: 28px;
        padding-top: 3px;
      }

      .icon {
        margin: 0 0 0 16px;
      }
    }

    &:hover {
      color: ${props => props.theme.bodyColor};
      fill: ${props => props.theme.bodyColor};
    }

    &.grey {
      border-color: #999;

      &:hover, &:focus {
        background-color: #999;
      }
    }

    &.green {
      border-color: ${vars.GREEN};

      &:hover, &:focus {
        background-color: ${vars.GREEN};
      }
    }

    &.students {
      border-color: ${vars.STUDENTS};

      &:hover {
        background-color: ${vars.STUDENTS};
      }
    }

    &.teachers {
      border-color: ${vars.TEACHERS};

      &:hover {
        background-color: ${vars.TEACHERS};
      }
    }

    &.contacts {
      border-color: ${vars.CONTACTS};

      &:hover {
        background-color: ${vars.CONTACTS};
      }
    }

    &.prospects {
      border-color: ${vars.PROSPECTS};

      &:hover {
        background-color: ${vars.PROSPECTS};
      }
    }

    &.disabled {
      opacity: .2;
      pointer-events: none;
    }
  }

  .radio-item {
    cursor: pointer;
    position: relative;
    display: flex;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    .radio-item-dot {
      width: 14px;
      min-width: 14px;
      height: 14px;
      border-radius: 50%;
      padding: 2px;
      margin-top: 6px;

      @media (max-width: 767px) {
        margin-top: 4px;
      }

      .radio-item-dot-inner {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: none;
      }
    }

    .radio-item-label {
      width: calc(100% - 22px);
      font-size: ${vars.FONT_SIZE_NORMAL};
      line-height: 28px;
      padding-left: 10px;
      white-space: nowrap;

      @media (max-width: 767px) {
        white-space: normal;
        line-height: 24px;
      }
    }

    &.active {
      .radio-item-dot {
        .radio-item-dot-inner {
          display: block;
        }
      }
    }

    .radio-item-dot {
      border: 1px solid ${props => props.theme.textGreyDark};

      .radio-item-dot-inner {
        background-color: ${props => props.theme.textGreyDark};
      }
    }

    &.active {
      .radio-item-dot {
        border: 1px solid ${props => props.theme.textGreyLight};

        .radio-item-dot-inner {
          background-color: ${props => props.theme.textGreyLight};
        }
      }

      .radio-item-label {
        color: ${props => props.theme.textGreyLight};
      }
    }
  }

  .radio-items {
    position: relative;
    display: flex;

    .radio-item {
      margin-right: 20px;
    }
  }

  .switch {
    position: relative; 

    .switch-item {
      cursor: pointer;
      display: flex;
      white-space: nowrap;
      margin-top: 5px;
      align-items: center;

      .switch-item-box {
        position: relative;
        width: 32px;
        height: 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 0 3px;

        .switch-item-box-inner {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          transition: transform 0.25s;
          transition-property: transform, background-color;
        }
      }

      .switch-item-label {
        font-size: 14px;
        line-height: 28px;
        padding-left: 10px;
        transition: color 0.25s;
      }

      &.active {
        .switch-item-box {
          .switch-item-box-inner {
            transform: translateX(16px);
          }
        }
      }
    }

    .switch-item {
      .switch-item-box {
        background-color: ${props => props.theme.textGreyDark};

        .switch-item-box-inner {
          background-color: ${props => props.theme.bodyColorDark};
        }
      }

      &.active {
        .switch-item-box {
          background-color: ${vars.GREEN};

          .switch-item-box-inner {
            background-color: ${props => props.theme.textGreyLight};
          }
        }

        .switch-item-label {
          color: ${props => props.theme.textGreyLight};
        }
      }
    }

    .checkmark {
      top: 9px;
      right: -20px;
    }
  }

  .form-error {
    position: absolute;
    right: 0;
    display: flex;
    background-color: ${vars.WARNING_COLOR};
    padding: 5px 10px;
    align-items: center;
    line-height: 24px;
    z-index: 1;
    margin-left: 20px;

    .form-error-label {
      color: #fff;
      font-size: 14px;
    }

    &:before {
      content: "";
      position: absolute;
      left: -4px;
      top: 8px;
      width: 16px;
      height: 16px;
      background-color: ${vars.WARNING_COLOR};
      transform: rotate(45deg);
      z-index: -1;
    }
  }
`;

export default UI;