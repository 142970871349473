import {css} from 'styled-components';
import * as vars from "css/vars";
import {rgba} from 'polished';

const Widgets = css`
  .widgets {
    margin: 0 auto 40px;
    padding-top: 40px;
    max-width: 720px;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .widget {
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid ${props => props.theme.borderGreyDark};

    .widget-header {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      background-color: ${props => props.theme.bodyColorMiddle};

      .widget-header-icon {
        margin-right: 10px;

        .birthday-icon {
          margin-bottom: 8px;
        }
      }

      .widget-header-label {
        margin-top: 2px;
        font-size: 22px;
        font-family: ${vars.FONT_DEMI};
        color: ${props => props.theme.textGreyDark};

        span {
          font-family: ${vars.FONT_REGULAR};
        }
      }

      .info-icon {
        position: absolute;
        right: 14px
      }
    }
  }

  .widget-half {
    width: 340px;
    margin: 10px;

    @media (max-width: 767px) {
      width: 100%;
      margin: 10px auto;
      max-width: 340px;
    }
  }

  .birthdays {

    .birthdayslist {
      padding: 15px;
      line-height: 30px;

      .birthday-students-header {
        font-size: 14px;
        color: ${vars.STUDENTS};
        padding: 5px 10px;
      }

      .birthday-teachers-header {
        font-size: 14px;
        color: ${vars.TEACHERS};
        padding: 5px 10px;

        &:first-child {
          margin-top: 0;
        }
      }

      .birthday-item {
        display: flex;
        font-size: 16px;
        padding: 5px 10px;

        .person-name {
          width: 200px;
          padding-right: 20px;
          flex-grow: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .person-kind-student {
          color: ${vars.STUDENTS};
        }

        .person-kind-teacher {
          color: ${vars.TEACHERS};
        }

        .person-kind-otherPerson {
          color: ${vars.CONTACTS};
        }
      }
    }

    .birthdayslist {
      .birthday-students-header, .birthday-teachers-header {
        background-color: ${props => props.theme.bodyColorDark};
      }

      .birthday-item {
        background-color: ${props => props.theme.bodyColorMiddle};

        &:nth-child(even) {
          background-color: ${props => rgba(props.theme.bodyColorMiddle, .5)};
        }
      }
    }
  }

  .current-rate {
    .testphase-container {
      display: flex;
      padding: 15px;

      .remaining-container {
        width: 117px;
        margin-right: 20px;

        .remaining-label {
          font-size: 16px;
        }

        .remaining-days-container {
          margin-top: 10px;
          background-color: ${props => props.theme.bodyColorMiddle};
          border-radius: 6px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 15px;

          .remaining-days {
            color: ${vars.GREEN};
            font-size: 64px;
            line-height: 54px;
            font-family: ${vars.FONT_DEMI};
          }

          .remaining-days-label {
            color: ${vars.GREEN};
            font-size: 22px;
            line-height: 22px;
            font-family: ${vars.FONT_DEMI};
          }
        }
      }

      .copy-container {
        font-size: 14px;
        line-height: 18px;

        a {
          text-decoration: underline;
        }
      }
    }

    .current-rate-container {
      padding: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .current-rate-label {
        font-size: 32px;
        font-family: ${vars.FONT_DEMI};
        color: ${vars.GREEN};
      }

      .current-rate-testphase {
        margin-top: 10px;
        font-size: 14px;

        a {
          text-decoration: underline;
        }
      }

      .current-rate-usage-label {
        font-size: 18px;
        font-family: ${vars.FONT_DEMI};
        margin: 20px 0 10px;
      }

      .current-rate-usage {
        display: flex;
        font-size: 18px;

        .traffic-light {
          margin-left: 10px;
        }
      }
    }
  }

  .import {
    .inner {
      padding: 15px;

      .copy {
        font-size: 14px;
        line-height: 18px;
      }

      .oe-button {
        margin: 10px 0 0 auto;
        display: table;
      }
    }
  }

  .updates {
    .inner {
      padding: 15px;

      .copy, ul {
        font-size: 14px;
        line-height: 18px;
      }

      li {
        color: ${vars.GREEN};
      }

      a {
        color: ${vars.GREEN};
        text-decoration: underline;
      }

      .oe-button {
        margin: 10px 0 0 auto;
        display: table;
      }
    }
  }

  .upload-status {
    .widget-body {
      padding: 20px;

      .bar-container {
        display: flex;
        align-items: center;

        .current-max {
          width: 49px;
          min-width: 49px;
          height: 26px;
          background-color: ${props => props.theme.bodyColorMiddle};
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: ${vars.FONT_DEMI};
          font-size: 12px;
          padding-top: 1px;
        }

        .bar {
          flex-grow: 1;
          height: 12px;
          margin: 0 10px;
          border: 1px solid ${props => props.theme.borderGreyDark};
          border-radius: 11px;
          padding: 2px;

          .bar-inner {
            width: 0;
            height: 6px;
            border-radius: 9px;
          }
        }

        .percent {
          font-family: ${vars.FONT_DEMI};
          font-size: 18px;
        }

        &.green {
          .bar-inner {
            background-color: ${vars.GREEN};
          }

          .percent {
            color: ${vars.GREEN};
          }
        }

        &.yellow {
          .bar-inner {
            background-color: ${vars.STUDENTS};
          }

          .percent {
            color: ${vars.STUDENTS};
          }
        }

        &.red {
          .bar-inner {
            background-color: ${vars.WARNING_COLOR};
          }

          .percent {
            color: ${vars.WARNING_COLOR};
          }
        }
      }

      .upload-status-copy {
        margin-top: 10px;
        font-size: 14px;
      }

      .warning {
        color: ${vars.WARNING_COLOR};
      }
    }
  }

  .info-texts {
    .inner {
      padding: 15px;

      .item {
        padding-top: 20px;

        .item-header {
          display: flex;
          align-items: center;
          font-size: 18px;

          .students {
            color: ${vars.STUDENTS};
          }

          .teachers {
            color: ${vars.TEACHERS};
          }

          .oe-button {
            margin-left: auto;
          }
        }

        .item-from {
          font-size: 14px;
          margin: 10px 0;
        }

        .item-text {
          font-size: 18px;
        }

        &:first-child {
          padding-top: 0;
          padding-bottom: 20px;
          border-bottom: 1px solid ${props => props.theme.borderGreyDark};
        }

        &:only-child {
          padding-top: 0;
          padding-bottom: 0;
          border-bottom: none;
        }
      }
    }
  }
`;

export default Widgets;