import React, {useState} from 'react';
import ApiLoader from "components/ApiLoader";
import bauarbeiten from 'img/bauarbeiten.png';
import CloseIcon from "private/icons/CloseIcon";
import styled from "styled-components";
import {rgba} from "polished";
import * as vars from 'css/vars';
import {Trans} from "react-i18next";

const MaintenanceContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${props => rgba(props.theme.bodyColorDark, .8)};
  padding: 20px;
  z-index: 10000;
  overflow: hidden;
  border-top: 1px solid ${vars.GREEN};
  display: flex;

  img {
    min-width: 100px;
    width: 100px;
    height: auto;
    margin-right: 20px;
    align-self: flex-start;
  }

  p {
    padding-right: 50px;
    font-size: 16px;
    line-height: 24px;
  }

  .close-icon {
    position: absolute;
    right: 17px;
    top: 17px;
    cursor: pointer;
    width: 26px;
    height: 26px;

    svg {
      fill: #707070;
      transition: fill 0.25s;
    }

    &:hover {
      svg {
        fill: #999;
      }
    }
  }
`;

function UserOrGuest(props) {
    const [load, setLoad] = useState(true);
    const [login, setLogin] = useState(null);
    const [maintenanceMode, setMaintenanceMode] = useState('off');

    const Private = React.lazy(() => import('private/Private'));
    const Public = React.lazy(() => import('public/Public'));

    async function checkLogin() {
        setLogin(null);
        setLoad(true);
    }

    const onData = (data) => {
        setLoad(false);
        setMaintenanceMode(data.maintenanceMode);
        setLogin(data.status);
    };

    return (
        <>
            {load &&
                <ApiLoader url="/user/check" callback={onData}/>
            }
            {
                login === 'user' &&
                <Private history={props.history} handleLogout={checkLogin} onChangeTheme={props.onChangeTheme}/>
            }
            {
                login === 'guest' &&
                <Public userHasAuthenticated={checkLogin}/>
            }
            {maintenanceMode === "on" &&
                <MaintenanceContainer>
                    <img src={bauarbeiten} alt=""/>
                    <p>
                        <Trans i18nKey="translation:components.Update" ns="translation">
                            Wir installieren aktuell ein Softwareupdate. Während des Updates kann es aus technischen Gründen zu Beeinträchtigungen beim Arbeiten mit <strong>Orga</strong>Easy kommen. Wir
                            bedauern eventuell entstehende Unannehmlichkeiten und bitten um Ihr Verständnis.
                        </Trans>
                    </p>
                    <CloseIcon onClick={() => {
                        setMaintenanceMode('off')
                    }}/>
                </MaintenanceContainer>
            }
        </>
    );
}

export default UserOrGuest