import {css} from 'styled-components';
import * as vars from "css/vars";
import {rgba} from "polished";

const Flatpickr = css`
  .flatpickr-calendar {
    opacity: 0;
    display: none;
    text-align: center;
    visibility: hidden;
    padding: 0;
    animation: none;
    direction: ltr;
    border: 0;
    font-size: 14px;
    line-height: 24px;
    border-radius: 5px;
    position: absolute;
    width: 307.875px;
    box-sizing: border-box;
    touch-action: manipulation;
    background: ${props => props.theme.bodyColorDark};
    box-shadow: 1px 0 0 ${props => props.theme.borderGreyDark}, -1px 0 0 ${props => props.theme.borderGreyDark}, 0 1px 0 ${props => props.theme.borderGreyDark}, 0 -1px 0 ${props => props.theme.borderGreyDark};
    fill: ${props => props.theme.textGreyLight};
    color: ${props => props.theme.textGreyLight};

    &.arrowBottom {
      margin-top: -10px;
    }

    &.arrowTop {
      margin-top: 10px;
    }

    .flatpickr-time .numInputWrapper span.arrowUp:after {
      border-bottom-color: ${props => props.theme.textGreyLight};
    }

    .flatpickr-time .numInputWrapper span.arrowDown:after {
      border-top-color: ${props => props.theme.textGreyLight};
    }

    .flatpickr-day {
      &:hover {
        background-color: ${props => props.theme.bodyColorMiddle};
      }
    }

    &.arrowTop:before {
      border-bottom-color: ${props => props.theme.borderGreyDark};
    }

    &.arrowTop:after {
      border-bottom-color: ${props => props.theme.bodyColorDark};
    }

    &.arrowBottom:before {
      border-top-color: ${props => props.theme.borderGreyDark};
    }

    &.arrowBottom:after {
      border-top-color: ${props => props.theme.bodyColorDark};
    }

    .flatpickr-months .flatpickr-month {
      background: ${props => props.theme.bodyColorDark};
    }

    .flatpickr-current-month .flatpickr-monthDropdown-months {
      background: ${props => props.theme.bodyColorDark};
    }

    .flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
      background-color: ${props => props.theme.bodyColorDark};
    }

    span.flatpickr-weekday {
      background: ${props => props.theme.bodyColorDark};
    }

    .showTimeInput.hasTime .flatpickr-time {
      border-top: 1px solid ${props => props.theme.borderGreyDark};
    }
  }

  .flatpickr-calendar.open,
  .flatpickr-calendar.inline {
    opacity: 1;
    max-height: 640px;
    visibility: visible;
  }

  .flatpickr-calendar.open {
    display: inline-block;
    z-index: 99999;
  }

  .flatpickr-calendar.animate.open {
    animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .flatpickr-calendar.inline {
    display: block;
    position: relative;
    top: 2px;
  }

  .flatpickr-calendar.static {
    position: absolute;
    top: calc(100% + 2px);
  }

  .flatpickr-calendar.static.open {
    z-index: 999;
    display: block;
  }

  .flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
    box-shadow: none !important;
  }

  .flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
    box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  }

  .flatpickr-calendar .hasWeeks .dayContainer,
  .flatpickr-calendar .hasTime .dayContainer {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .flatpickr-calendar .hasWeeks .dayContainer {
    border-left: 0;
  }

  .flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
    height: 40px;
  }

  .flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
    height: auto;
  }

  .flatpickr-calendar:before,
  .flatpickr-calendar:after {
    position: absolute;
    display: block;
    pointer-events: none;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    left: 22px;
  }

  .flatpickr-calendar.rightMost:before,
  .flatpickr-calendar.rightMost:after {
    left: auto;
    right: 22px;
  }

  .flatpickr-calendar:before {
    border-width: 10px;
    margin: 0 -10px;
  }

  .flatpickr-calendar:after {
    border-width: 9px;
    margin: 0 -9px;
  }

  .flatpickr-calendar.arrowTop:before,
  .flatpickr-calendar.arrowTop:after {
    bottom: 100%;
  }

  .flatpickr-calendar.arrowBottom:before,
  .flatpickr-calendar.arrowBottom:after {
    top: 100%;
  }

  .flatpickr-calendar:focus {
    outline: 0;
  }

  .flatpickr-wrapper {
    position: relative;
    display: inline-block;
  }

  .flatpickr-months {
    display: flex;
    border-radius: 5px;
    overflow: hidden;
  }

  .flatpickr-months .flatpickr-month {
    color: ${props => props.theme.textGreyLight};
    fill: #fff;
    height: 34px;
    line-height: 1;
    text-align: center;
    position: relative;
    user-select: none;
    overflow: hidden;
    flex: 1;
  }

  .flatpickr-months .flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month {
    text-decoration: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    height: 34px;
    padding: 10px;
    z-index: 3;
  }

  .flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
  .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
    display: none;
  }

  .flatpickr-months .flatpickr-prev-month i,
  .flatpickr-months .flatpickr-next-month i {
    position: relative;
  }

  .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
    left: 0;
  }

  .flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
  .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
    right: 0;
  }

  .flatpickr-months .flatpickr-prev-month svg,
  .flatpickr-months .flatpickr-next-month svg {
    width: 14px;
    height: 14px;
  }

  .numInputWrapper {
    position: relative;
    height: auto;
  }

  .numInputWrapper input,
  .numInputWrapper span {
    display: inline-block;
  }

  .numInputWrapper input {
    width: 100%;
  }

  .numInputWrapper input::-ms-clear {
    display: none;
  }

  .numInputWrapper input::-webkit-outer-spin-button,
  .numInputWrapper input::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  .numInputWrapper span {
    position: absolute;
    right: 0;
    width: 14px;
    padding: 0 4px 0 2px;
    height: 50%;
    line-height: 50%;
    opacity: 0;
    cursor: pointer;
    box-sizing: border-box;
  }

  .numInputWrapper span:after {
    display: block;
    content: "";
    position: absolute;
  }

  .numInputWrapper span.arrowUp {
    top: 0;
    border-bottom: 0;
  }

  .numInputWrapper span.arrowUp:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid rgba(255, 255, 255, 0.6);
    top: 26%;
  }

  .numInputWrapper span.arrowDown {
    top: 50%;
  }

  .numInputWrapper span.arrowDown:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid rgba(255, 255, 255, 0.6);
    top: 40%;
  }

  .numInputWrapper span svg {
    width: inherit;
    height: auto;
  }

  .numInputWrapper:hover span {
    opacity: 1;
  }

  .flatpickr-current-month {
    font-size: 135%;
    font-weight: 300;
    color: inherit;
    position: absolute;
    width: 75%;
    left: 12.5%;
    padding: 7.48px 0 0 0;
    line-height: 1;
    height: 34px;
    display: inline-block;
    text-align: center;
    transform: translate3d(0px, 0px, 0px);
  }

  .flatpickr-current-month span.cur-month {
    font-family: inherit;
    font-weight: 700;
    color: inherit;
    display: inline-block;
    margin-left: 0.5ch;
    padding: 0;
  }

  .flatpickr-current-month .numInputWrapper {
    width: 6ch;
    display: inline-block;
  }

  .flatpickr-current-month .numInputWrapper span.arrowUp:after {
    border-bottom-color: #fff;
  }

  .flatpickr-current-month .numInputWrapper span.arrowDown:after {
    border-top-color: #fff;
  }

  .flatpickr-current-month input.cur-year {
    background: transparent;
    box-sizing: border-box;
    color: inherit;
    cursor: text;
    padding: 0 0 0 0.5ch;
    margin: 0;
    display: inline-block;
    font-size: inherit;
    font-family: inherit;
    font-weight: 300;
    line-height: inherit;
    height: auto;
    border: 0;
    border-radius: 0;
    vertical-align: initial;
    appearance: textfield;
  }

  .flatpickr-current-month input.cur-year:focus {
    outline: 0;
  }

  .flatpickr-current-month input.cur-year[disabled],
  .flatpickr-current-month input.cur-year[disabled]:hover {
    font-size: 100%;
    color: ${props => rgba(props.theme.textGreyLight, .5)};
    background: transparent;
    pointer-events: none;
  }

  .flatpickr-current-month .flatpickr-monthDropdown-months {
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    color: inherit;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    font-weight: 300;
    height: auto;
    line-height: inherit;
    margin: -1px 0 0 0;
    outline: none;
    padding: 0 0 0 0.5ch;
    position: relative;
    vertical-align: initial;
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    width: auto;
  }

  .flatpickr-current-month .flatpickr-monthDropdown-months:focus,
  .flatpickr-current-month .flatpickr-monthDropdown-months:active {
    outline: none;
  }

  .flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
    outline: none;
    padding: 0;
  }

  .flatpickr-weekdays {
    background: transparent;
    text-align: center;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    height: 28px;
  }

  .flatpickr-weekdays .flatpickr-weekdaycontainer {
    display: flex;
    flex: 1;
  }

  span.flatpickr-weekday {
    cursor: default;
    font-size: 90%;
    color: ${props => props.theme.textGreyLight};
    line-height: 1;
    margin: 0;
    text-align: center;
    display: block;
    flex: 1;
    font-weight: bolder;
  }

  .dayContainer,
  .flatpickr-weeks {
    padding: 1px 0 0 0;
  }

  .flatpickr-days {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    width: 307.875px;
  }

  .flatpickr-days:focus {
    outline: 0;
  }

  .dayContainer {
    padding: 0;
    outline: 0;
    text-align: left;
    width: 307.875px;
    min-width: 307.875px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }

  .dayContainer + .dayContainer {
    box-shadow: -1px 0 0 #20222c;
  }

  .flatpickr-day {
    background: none;
    border: 1px solid transparent;
    border-radius: 150px;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: 400;
    width: 14.2857143%;
    flex-basis: 14.2857143%;
    max-width: 39px;
    height: 39px;
    line-height: 39px;
    margin: 0;
    display: inline-block;
    position: relative;
    justify-content: center;
    text-align: center;
  }

  .flatpickr-day.inRange,
  .flatpickr-day.prevMonthDay.inRange,
  .flatpickr-day.nextMonthDay.inRange,
  .flatpickr-day.today.inRange,
  .flatpickr-day.prevMonthDay.today.inRange,
  .flatpickr-day.nextMonthDay.today.inRange,
  .flatpickr-day:hover,
  .flatpickr-day.prevMonthDay:hover,
  .flatpickr-day.nextMonthDay:hover,
  .flatpickr-day:focus,
  .flatpickr-day.prevMonthDay:focus,
  .flatpickr-day.nextMonthDay:focus {
    cursor: pointer;
    outline: 0;
  }

  .flatpickr-day.today {
    border-color: #eee;
  }

  .flatpickr-day.today:hover,
  .flatpickr-day.today:focus {
    border-color: #eee;
    background: #eee;
    color: #3f4458;
  }

  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    background-color: ${vars.GREEN} !important;
    box-shadow: none;
    color: #fff;
    border: none;
  }

  .flatpickr-day.selected.startRange,
  .flatpickr-day.startRange.startRange,
  .flatpickr-day.endRange.startRange {
    border-radius: 50px 0 0 50px;
  }

  .flatpickr-day.selected.endRange,
  .flatpickr-day.startRange.endRange,
  .flatpickr-day.endRange.endRange {
    border-radius: 0 50px 50px 0;
  }

  .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
  .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
  .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
    box-shadow: -10px 0 0 #80cbc4;
  }

  .flatpickr-day.selected.startRange.endRange,
  .flatpickr-day.startRange.startRange.endRange,
  .flatpickr-day.endRange.startRange.endRange {
    border-radius: 50px;
  }

  .flatpickr-day.inRange {
    border-radius: 0;
    box-shadow: -5px 0 0 #646c8c, 5px 0 0 #646c8c;
  }

  .flatpickr-day.flatpickr-disabled,
  .flatpickr-day.flatpickr-disabled:hover,
  .flatpickr-day.prevMonthDay,
  .flatpickr-day.nextMonthDay,
  .flatpickr-day.notAllowed,
  .flatpickr-day.notAllowed.prevMonthDay,
  .flatpickr-day.notAllowed.nextMonthDay {
    color: ${props => rgba(props.theme.textGreyLight, .3)};
    background: transparent;
    border-color: transparent;
    cursor: default;
  }

  .flatpickr-day.flatpickr-disabled,
  .flatpickr-day.flatpickr-disabled:hover {
    cursor: not-allowed;
    color: ${props => rgba(props.theme.textGreyLight, .3)};
  }

  .flatpickr-day.hidden {
    visibility: hidden;
  }

  .flatpickr-innerContainer {
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
  }

  .flatpickr-rContainer {
    display: inline-block;
    padding: 0;
    box-sizing: border-box;
  }

  .flatpickr-time {
    text-align: center;
    outline: 0;
    height: 0;
    line-height: 40px;
    max-height: 40px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
  }

  .flatpickr-time:after {
    content: "";
    display: table;
    clear: both;
  }

  .flatpickr-time .numInputWrapper {
    flex: 1;
    width: 40%;
    height: 40px;
    float: left;
  }

  .flatpickr-time.hasSeconds .numInputWrapper {
    width: 26%;
  }

  .flatpickr-time.time24hr .numInputWrapper {
    width: 49%;
  }

  .flatpickr-time input {
    background: transparent;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    text-align: center;
    margin: 0;
    padding: 0;
    height: inherit;
    line-height: inherit;
    font-size: 14px;
    position: relative;
    box-sizing: border-box;
    appearance: textfield;
  }

  .flatpickr-time input.flatpickr-hour {
    font-weight: bold;
  }

  .flatpickr-time input.flatpickr-minute,
  .flatpickr-time input.flatpickr-second {
    font-weight: 400;
  }

  .flatpickr-time input:focus {
    outline: 0;
    border: 0;
  }

  .flatpickr-time .flatpickr-time-separator,
  .flatpickr-time .flatpickr-am-pm {
    height: inherit;
    float: left;
    line-height: inherit;
    font-weight: bold;
    width: 2%;
    user-select: none;
    align-self: center;
  }

  .flatpickr-time .flatpickr-am-pm {
    outline: 0;
    width: 18%;
    cursor: pointer;
    text-align: center;
    font-weight: 400;
  }

  .flatpickr-input[readonly] {
    cursor: pointer;
  }

  @keyframes fpFadeInDown {
    from {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
`;

export default Flatpickr;