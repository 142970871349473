import {createGlobalStyle} from "styled-components";
import Common from "css/common/Common";
import Paragraphs from "css/common/Paragraphs";
import UI from "css/common/UI";
import Form from "css/common/Form";
import Simplebar from "css/common/Simplebar";
import Flatpickr from "css/common/Flatpickr";
import Modal from "css/common/Modal";
import Dropzone from "css/common/Dropzone";
import Icons from "css/common/Icons";
import Widgets from "css/common/Widgets";

const GlobalStyle = createGlobalStyle`
  ${Common};
  ${Simplebar};
  ${Form};
  ${Dropzone};
  ${Paragraphs};
  ${Flatpickr};
  ${Modal};
  ${UI};
  ${Icons};
  ${Widgets};
`;

export default GlobalStyle;