export const FONT_REGULAR = 'AvenirNextLTPro-Regular, sans-serif';
export const FONT_DEMI = 'AvenirNextLTPro-Demi, sans-serif';
export const FONT_SIZE_NORMAL = '18px';
export const FONT_SIZE_NORMAL_MOBILE = '14px';

export const GREEN = '#32b432';
export const STUDENTS = '#dd9300';
export const TEACHERS = '#EC553B';
export const CONTACTS = '#56d3f1';
export const PROSPECTS = '#36CE9F';
export const WARNING_COLOR = '#FF0000';

export const HEADER_HEIGHT = '119px';
export const HEADER_HEIGHT_MOBILE = '90px';

export const MODAL_HEADER_HEIGHT = '62px';

export const DARK = {
    bodyColor: '#333',
    bodyColorDark: '#222',
    bodyColorMiddle: '#2C2C2C',
    textGreyLight: '#E2E2E2',
    textGreyDark: '#838383',
    borderGreyDark: '#484848',
    test: '#5C5C5C'
};

export const LIGHT = {
    bodyColor: '#fffdf7',
    bodyColorDark: '#F5F4ED',
    bodyColorMiddle: '#FBF9F3',
    textGreyLight: '#333331',
    textGreyDark: '#666563',
    borderGreyDark: '#e8e6df'
};