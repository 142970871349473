import {css} from 'styled-components';
import * as vars from "css/vars";

const Dropzone = css`
  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    outline: none;
    cursor: pointer;
    transition: color 0.25s;
    border-color: ${props => props.theme.borderGreyDark};
    background-color: ${props => props.theme.bodyColorDark};

    &:hover {
      color: ${props => props.theme.textGreyLight};
    }

    p {
      font-size: 14px;
      text-align: center;
      max-width: 80%;
    }

    img {
      max-height: 200px;
      max-width: 80%;
      width: auto;
      margin-bottom: 20px;
    }

    .progress-bar-container {
      max-width: 278px;
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 10px;

      .progress-bar {
        width: 100%;
        height: 12px;
        border-radius: 11px;
        padding: 2px;
        border: 1px solid ${props => props.theme.borderGreyDark};

        .progress-bar-inner {
          width: 0;
          height: 6px;
          border-radius: 9px;
          background-color: ${vars.GREEN};
        }
      }

      .progress-bar-text {
        color: ${vars.GREEN};
        font-family: ${vars.FONT_DEMI};
        font-size: ${vars.FONT_SIZE_NORMAL};
        width: 64px;
        min-width: 64px;
        text-align: right;
      }
    }
  }
`;

export default Dropzone;