import { css } from 'styled-components';
import * as vars from "css/vars";

const Paragraphs = css`
  a {
    color: inherit;
    text-decoration: none;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    font-weight: normal;
    line-height: 1.2;
  }

  h1 {
    font-size: 48px;
    font-family: ${vars.FONT_DEMI};

    @media (max-width: 767px) {
      font-size: 32px;
    }
  }

  h2 {
    font-size: 36px;

    @media (max-width: 767px) {
      font-size: 27px;
    }
  }

  h3 {
    font-size: 32px;

    @media (max-width: 767px) {
      font-size: 24px;
    }
  }

  h4 {
    font-size: ${vars.FONT_SIZE_NORMAL};
    line-height: 25px;
    color: ${vars.GREEN};
    margin-bottom: 30px;
  }

  h5 {
    font-size: ${vars.FONT_SIZE_NORMAL};
    line-height: 25px;
    color: ${vars.GREEN};

    strong {
      font-family: ${vars.FONT_DEMI};
      font-weight: normal;
    }
  }

  h6 {
    font-size: ${vars.FONT_SIZE_NORMAL};
  }

  p, ul {
    font-size: ${vars.FONT_SIZE_NORMAL};
    line-height: 25px;

    strong {
      font-family: ${vars.FONT_DEMI};
      font-weight: normal;
    }

    a {
      text-decoration: underline;
    }
  }

  .asterisk {
    margin-top: 8px;
    font-size: 14px;
    line-height: 19px;

    &:first-child {
      margin-top: 0;
    }

    .warning {
      display: flex;
      align-items: flex-start;
      padding-top: 10px;
      color: ${vars.WARNING_COLOR};

      .warning-icon {
        width: 16px;
        height: 14px;
        fill: ${vars.WARNING_COLOR};
        margin: 0px 8px 0 0;
      }
    }

    a {
      color: ${vars.GREEN};
    }
  }
`;

export default Paragraphs;