import {css} from 'styled-components';
import {rgba} from 'polished';
import * as vars from "css/vars";

const Modal = css`
  .modal-overlay-alpha {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 200;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => rgba(props.theme.bodyColor, .7)};

    .modal-inner {
      position: relative;
      outline: none;
    }
  }

  .modal-dialogue {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 200;
    top: 0;
    left: 0;
    background-color: rgba(${props => props.theme.bodyColor}, .7);

    .modal-inner {
      position: relative;
      outline: none;
      height: 100%;

      .simplebar-content {
        height: 100%;
      }

      .modal-body {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;

        .delete-dialogue {
          max-width: 640px;
          padding: 20px;
          border-radius: 8px;

          @media (max-width: 767px) {
            max-width: 300px;
          }

          .dialogue-label {
            font-size: 14px;
            line-height: 18px;

            .warning {
              color: ${vars.WARNING_COLOR};
            }

            .warning-icon {
              width: 16px;
              height: 14px;
              fill: ${vars.WARNING_COLOR};
              margin: 0 8px 0 0;
              display: inline-block;
            }
          }

          .dialogue-buttons {
            display: flex;
            justify-content: center;
            padding-top: 20px;
            margin-top: 20px;

            .oe-button {
              margin: 0 5px;
            }
          }
        }

        .link-dialogue {
          width: 300px;
          padding: 20px;
          border-radius: 8px;

          .dialogue-input {
            .editableFieldInput {
              position: relative;
              border: none;
              outline: none;
              padding: 0 4px;
              line-height: 28px;
              border-radius: 2px;
              font-size: 18px;
              margin: 0;
              width: 100%;
            }
          }

          .dialogue-buttons {
            display: flex;
            justify-content: center;
            padding-top: 20px;
            margin-top: 20px;

            .oe-button {
              margin: 0 5px;
            }
          }
        }

        .info-dialogue {
          max-width: 640px;
          padding: 20px;
          border-radius: 8px;
          background-color: ${props => props.theme.bodyColorDark};
          border: 1px solid ${props => props.theme.borderGreyDark};

          @media (max-width: 767px) {
            max-width: 300px;
          }

          .dialogue-label {

            p {
              font-size: 14px;
              line-height: 18px;
            }

            h5 {
              font-family: ${vars.FONT_DEMI};
              margin-bottom: 10px;
            }

            a {
              color: ${vars.GREEN};
              text-decoration: none;
            }

            .warning {
              color: ${vars.WARNING_COLOR};
            }

            .green-bold {
              color: ${vars.GREEN};
              font-family: ${vars.FONT_DEMI};
            }

            .warning-icon {
              width: 16px;
              height: 14px;
              fill: ${vars.WARNING_COLOR};
              margin: 0 8px 0 0;
              display: inline-block;
            }
          }

          .dialogue-buttons {
            display: flex;
            justify-content: flex-end;
            padding-top: 20px;
            margin-top: 20px;
            border-top: 1px solid ${props => props.theme.borderGreyDark};

            .oe-button {
              margin: 0 5px;
            }
          }

          .infos {

            .info-headline {
              font-family: ${vars.FONT_DEMI};
              font-size: 18px;
              margin: 25px 0 5px;

              &:first-child {
                margin-top: 0px;
              }

              &.green {
                color: ${vars.GREEN};
              }

              &.red {
                color: ${vars.TEACHERS};
              }

              &.yellow {
                color: ${vars.STUDENTS};
              }
            }

            .info-text {
              font-size: 18px;
            }

            .multiline {
              white-space: pre-line;
            }
          }
        }
      }
    }

    .modal-inner {
      .modal-body {
        .delete-dialogue, .link-dialogue {
          background-color: ${props => props.theme.bodyColorDark};
          border: 1px solid ${props => props.theme.borderGreyDark};

          .dialogue-buttons {
            border-top: 1px solid ${props => props.theme.borderGreyDark};
          }

          .dialogue-input {
            .editableFieldInput {
              background-color: ${props => props.theme.bodyColor};
            }
          }
        }
      }
    }
  }
`;

export default Modal;