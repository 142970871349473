import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import * as vars from "css/vars";
import {useTranslation} from "react-i18next";

const ApiLoaderDebug = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: ${vars.GREEN};
  color: #000;
  font-size: 11px;
  padding: 5px 10px;
`;

function ApiLoader(props) {
    const [error, setError] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        async function load() {
            let endpoint = window.config.API_ENDPOINT;
            if(props.endpoint) {
                endpoint = props.endpoint;
            }
            endpoint += props.url;

            let options = {
                credentials: 'include'
            };

            if (props.params) {
                var data = new FormData();
                for (let k in props.params) {
                    data.append(k, props.params[k]);
                }

                options.method = "POST";
                options.body = data;
            }
            try {
                const response = await fetch(endpoint, options);
                let json = await response.json();
                props.callback(json);
            } catch (error) {
                console.log(error);
                setError(error.toString());
            }
        }

        load();
        // eslint-disable-next-line
    }, []);

    return (
        error
            // ? <div className="api-loader-debug">{error}</div>
            ? <ApiLoaderDebug>{t('translation:components.ApiLoader')}</ApiLoaderDebug>
            : null
    );
}

export default ApiLoader;