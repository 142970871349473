import {css} from 'styled-components';
import * as vars from "css/vars";

const Icons = css`
  .checkmark {
    position: absolute;
    right: -15px;
    top: 3px;
    border-right: 2px solid #299A29;
    border-bottom: 2px solid #299A29;
    height: 15px;
    width: 8px;
    transform: rotate(45deg);
    opacity: 0;
    pointer-events: none;
  }

  .traffic-light {
    position: relative;
    width: 20px;
    height: 20px;
    font-size: 0;
    margin-bottom: 2px;

    &.traffic-light-green {
      fill: ${vars.GREEN};
    }

    &.traffic-light-yellow {
      fill: ${vars.STUDENTS};
    }

    &.traffic-light-red {
      fill: ${vars.WARNING_COLOR};
    }
  }

  .duplicate-icon {
    position: relative;
    width: 21px;
    height: 21px;

    svg {
      transition: fill 0.25s;
    }
  }

  .trash-icon {
    position: relative;
    width: 17px;
    height: 22px;
    cursor: pointer;

    svg {
      transition: fill 0.25s;
    }
  }

  .time-icon {
    position: relative;
    width: 18px;
    height: 18px;
    cursor: pointer;

    svg {
      transition: fill 0.25s;
    }
  }

  .drag-handle-icon {
    position: relative;
    width: 22px;
    height: 11px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .drag-handle-icon-line {
      width: 100%;
      height: 1px;
      background-color: #575757;
      border-bottom: #000;
    }
  }

  .small-x-icon {
    position: relative;
    width: 10px;
    height: 10px;
    fill: #FF0000;
  }

  .group-icon {
    position: relative;
    width: 22px;
    height: 15px;
  }

  .no-group-icon {
    position: relative;
    width: 13px;
    height: 16px;
  }

  .house-icon {
    position: relative;
    width: 16px;
    height: 16px;
  }

  .arrow-right-icon {
    position: relative;
    width: 8px;
    height: 14px;
  }

  .arrow-left-icon {
    position: relative;
    width: 8px;
    height: 14px;
  }

  .print-icon {
    position: relative;
    width: 21px;
    height: 20px;
  }

  .edit-icon {
    position: absolute;
    cursor: pointer;
    pointer-events: none;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 4px;
    width: 20px;
    height: 20px;
    z-index: -1;
  }

  .download-icon {
    width: 16.7px;
    height: 18.02px;
  }

  .birthday-icon {
    width: 22.13px;
    height: 22.16px;
  }

  .sample-lesson-icon {
    fill: ${vars.PROSPECTS};
  }

  .overlapping-lesson-icon {
    fill: #E52421;
    width: 20px;
    height: 20px;
  }

  .overlapping-teacher-icon {
    fill: #E52421;
    width: 20px;
    height: 20px;
  }

  .notice-icon {
    width: 20px;
    height: 20px;
  }

  .row-lesson-icon, .single-lesson-icon {
    fill: #838383;
    width: 20px;
    height: 20px;
  }

  .changes-lesson-icon {
    width: 20px;
    height: 20px;

    .st0 {
      fill: #F4E51A;
    }

    .st1 {
      fill: #010202;
    }
  }

  .reload-icon {
    width: 16px;
    height: 17px;
  }

  .archive-icon {
    width: 21px;
    height: 20px;
  }

  .unarchive-icon {
    width: 21px;
    height: 20px;
  }

  .star-icon {
    width: 19px;
    height: 18px;
  }

  .todo-icon {
    width: 15px;
    height: 16px;
  }

  .todo-done-icon {
    width: 18px;
    height: 12px;
  }

  .note-icon {
    width: 15px;
    height: 16px;
  }

  .orga-easy-icon {
    width: 25px;
    height: 21px;
  }

  .library-view-item-icon {
    width: 13.73px;
    height: 16px;
  }

  .upload-icon {
    width: 14.84px;
    height: 16px;
  }

  .lock-icon {
    display: inline-block;
    width: 17.436px;
    height: 21px;
  }

  .duplicate-icon, .trash-icon, .time-icon {
    svg {
      fill: ${props => props.theme.textGreyDark};
    }

    &:hover {
      svg {
        fill: ${props => props.theme.textGreyLight};
      }
    }
  }

  .group-icon, .no-group-icon, .house-icon, .birthday-icon {
    fill: ${props => props.theme.textGreyDark};
  }

  .edit-icon {
    svg {
      fill: ${props => props.theme.textGreyLight};
    }
  }
`;

export default Icons;